.oneMedia {

    max-width: 1300px;
    margin: 0 auto;
    margin-top:30px;
    margin-bottom:30px;

    img {
        width: 100%;
        height: 100%;
    }
}