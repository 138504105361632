@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {

    @media (max-width: 821px) {
        cursor: inherit !important;
    }

    .isDark {
        background: black!important;

        h1, h2, article, article p, a, .slickPrev,  .slickNext, span, .asideText, i, .icon-search {
            color: white!important;  
        }

        .searchBar {
            background: black!important;
        }

        .showSearchBar {
            border-color: white!important;
        }

        .nav-right ul li a {
            color: #818181!important; 
            &:hover {
                color: white!important; 
            }
        }

        .codirMemberInput p {
            span {
                color: white!important;  
            }
        }

        .btnSkew div {
            background-color: black!important;
        } 

        .btnSkew div span {
            color: white!important; 
            
            &:hover {
                color: black!important;
            }
        }

        .containerImgSkew2 p {
            color: black!important;

            span {
                color: black!important;
            }
        }

        .containerQuestionAnswer article p{
            color: black!important;
        }

        .contactInfo p {
            color: white!important;  
        }

        .icon-burger-close {
            background-image: url("/images/burger-white.svg")!important;
        }

        .icon-search {
            background-image: url("/images/loupewhite.png")!important;
        }

        .translationSelection {
            background-color: black!important;
        }

        .navFooter h3, .navFooterOther h3, .navJuridic h3 {
            color: white!important;
        }

        .chevronLeft {
            background-image: url("/images/returnWhite.png");
        }

        .containerIconPropos div p {
            color: white!important;
        }

        .slickPartners p {
            color: white!important;
        }
    }
}

body {
    font-family: 'Poppins', Poppins Light, Poppins Regular, Poppins SemiBold, Poppins ExtraBold;
    margin: 0;
    box-sizing: border-box;

    .containerUxia {
        padding:80px 0 0 0;
    }

    .slick-slide {
        outline: none!important;
    }
}

h1, h2 h3, h4, h5, h6 {
    margin: 0;
}

textarea, select, input, button {
    outline: none;
}

.timeline-TweetList-tweet, .timeline-TweetList {
   display: flex!important;
}

@import url('//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css');
@import "layout/header.scss";
@import "layout/footer.scss";
@import "layout/error.scss";
@import "layout/btnSkew.scss";
@import "layout/modal.scss";

@import "page/home.scss";
@import "page/actualite.scss";
@import "page/production.scss";
